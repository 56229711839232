import React from 'react';
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import videoone from '../../Assets/Composite.mp4';
import videotwo from '../../Assets/Spiderman.mp4';
import videothree from '../../Assets/spidervspigeon.mp4';
import videofour from '../../Assets/Batmobile.mp4';
import videofive from '../../Assets/Batman_Theme_Song.mp4';
import videosix from '../../Assets/box.mp4';
import videoseven from '../../Assets/LopezDonald.mp4';
import videoeight from '../../Assets/LopezDonaldproj5.mp4';
import videonine from '../../Assets/Particleswsound.mp4';
import videoten from '../../Assets/spiderdropsounds.mp4';
import imagethirtyfive from '../../Assets/spiderman1.png';
import imagethirtysix from '../../Assets/spiderman2.png';
import imagethirtyseven from '../../Assets/spiderman3.png';
import imagethirtyeight from '../../Assets/spiderman4.png';
import imagethirtynine from '../../Assets/Batmobile profilemaya.png';
import imagefourty from '../../Assets/Batmobile profile.jpg';
import imagefourtyone from '../../Assets/batman1.png';
import imagefourtytwo from '../../Assets/Finished.jpg';
import imagefourtythree from '../../Assets/Finished1.jpg';
import imagefourtyfour from '../../Assets/Finished2.png';
import imagefourtyfive from '../../Assets/Finished3.png';
import imagefourtysix from '../../Assets/Atmosphere1.jpg';
import imagefourtyseven from '../../Assets/sinjin.png';
import imagefourtyeight from '../../Assets/Lake.png';
import imagefourtynine from '../../Assets/plane1.png';
import imagefifty from '../../Assets/plane2.png';

function Animationpage() {
  return (
    <div className="aboutpagebackground">
      <div className="">
        <h2 className="text-center">
          <b>
            Animations <span> and Motion Graphics </span>
          </b>
        </h2>
      </div>
      {/*first column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <video width="100%" height="auto" alt="" controls>
            <source src={videoone} type="video/mp4"/>
            </video>
          </Col>
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videothree} type="video/mp4"/>
            </video>
          </Col>
        </Row>
      </Container>
      {/*second column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videofour} type="video/mp4"/>
            </video>
          </Col>
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videotwo} type="video/mp4"/>
            </video>
          </Col>
        </Row>
      </Container>
      {/*three column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videoseven} type="video/mp4"/>
            </video>
          </Col>
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videoten} type="video/mp4"/>
            </video>
          </Col>
        </Row>
      </Container>
      {/*four column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videoeight} type="video/mp4"/>
            </video>
          </Col>
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videonine} type="video/mp4"/>
            </video>
          </Col>
        </Row>
      </Container>
      {/*five column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videofive} type="video/mp4"/>
            </video>
          </Col>
          <Col size="md">
          <video width="100%" height="auto" alt="" controls>
            <source src={videosix} type="video/mp4"/>
            </video>
          </Col>
        </Row>
      </Container>
      {/*six column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagethirtyfive} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagethirtysix} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*seven column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagethirtyseven} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagethirtyeight} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*eight column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagethirtynine} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagefourty} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
           {/*nine column*/}
           <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefourtyone} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagefourtytwo} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
           {/*ten column*/}
           <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefourtythree} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagefourtyfour} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
                 {/*eleven column*/}
                 <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefourtyfive} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagefourtysix} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
                 {/*twelve column*/}
                 <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefourtyseven} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagefourtyeight} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
                 {/*thirteen column*/}
                 <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefourtynine} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagefifty} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
    
    </div>
  );
}

export default Animationpage;
