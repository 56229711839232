import React from 'react'
import Header from '../components/Header/Header'
import Drawingpage from '../components/Drawingpage/Drawingpage'


function Drawing() {
  return (
    <div>
      <Header />
      <Drawingpage />
    </div>
  )
}

export default Drawing