import React from 'react'
import Header from '../components/Header/Header'
import Animationpage from '../components/Animationpage/Animationpage'


function Animation() {
  return (
    <div>
      <Header />
      <Animationpage />
    </div>
  )
}

export default Animation;
