import React from 'react'
import Header from '../components/Header/Header'
import Reelpage from '../components/Reelpage/Reelpage'


function Reel() {
  return (
    <div>
      <Header />
      <Reelpage />
    </div>
  )
}

export default Reel