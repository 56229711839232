import React from 'react';
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import video from '../../Assets/3DReel.mp4'

function Reelpage() {
  return (
    <div className="aboutpagebackground">
      <Container>
        <Row className="textbackground">
          <Col md={7}>
            <h3 className="aboutmetext">
              3D <span>Reel</span>
            </h3>
            <div className="aboutdetails">
            <video width="100%" height="auto" controls >
      <source src={video} type="video/mp4"/>
     </video>
            </div>
            <ul className="skilllist">
              <Row>
                <h3>Skills</h3>
                <Col md={7}>
                  <li>Autodesk Maya</li>
                  <li>Autodesk Mudbox</li>
                  <li>Adobe After Effects</li>
                  <li>Adobe Illustrator</li>
                  <li>Adobe Photoshop</li>
                </Col>
                <Col md={5}>
                  <li>Project Management</li>
                  <li>Storyboarding</li>
                  <li>Hand Drawing</li>
                  <li>Story Telling</li>
                  <li>Canvas Painting</li>
                </Col>
              </Row>
            </ul>
          </Col>
          <Col md={5}>
            <div className="webimage"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Reelpage;
