import React from 'react'
import '../../pages/style.css';
import ProjectCard from "./ProjectCard";
import { Container, Row, Col } from 'react-bootstrap'
import cyoa from '../../Assets/CYOA.png'
import store from '../../Assets/Store.png'
import borgwebsite from '../../Assets/Borgwebsite.png'
import chatmodal from '../../Assets/ChatModal.png'
import weatherapp from '../../Assets/WeatherApp.png'
import ecommerce from '../../Assets/Ecommerce.png'

function Projectlist() {
  return (
    <div className="projectbackground">
      <Container fluid className="project-section">
        <Container>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={cyoa}
                isBlog={false}
                title="CYOA Game"
                description="Playing the game requires internet and the ability to read. You must also be aware this game is for a mature audience and not for the feint hearted. Please remember to keep all hands and feet inside at all times, as you could be eaten by a Zombie. Thanks again for flying Alterra the only inter-galactic space agency this side of the Milky Way."
                ghLink="https://"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={store}
                isBlog={false}
                title="Store App"
                description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores autem eos consequuntur, exercitationem esse repellat voluptates, quibusdam repudiandae corrupti voluptatem ducimus. Sit, dolorem obcaecati. Cumque repellendus rerum veritatis reprehenderit maiores a, porro rem, molestias corrupti esse dolore nobis fuga odio."
                ghLink="https://"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={borgwebsite}
                isBlog={false}
                title="Borg-Website"
                description="     Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores autem eos consequuntur, exercitationem esse repellat voluptates, quibusdam repudiandae corrupti voluptatem ducimus. Sit, dolorem obcaecati. Cumque repellendus rerum veritatis reprehenderit maiores a, porro rem, molestias corrupti esse dolore nobis fuga odio."
                ghLink="https://"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={chatmodal}
                isBlog={false}
                title="Chat Modal"
                description="     Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores autem eos consequuntur, exercitationem esse repellat voluptates, quibusdam repudiandae corrupti voluptatem ducimus. Sit, dolorem obcaecati. Cumque repellendus rerum veritatis reprehenderit maiores a, porro rem, molestias corrupti esse dolore nobis fuga odio."
                ghLink="https://"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={weatherapp}
                isBlog={false}
                title="Weather App"
                description="     Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores autem eos consequuntur, exercitationem esse repellat voluptates, quibusdam repudiandae corrupti voluptatem ducimus. Sit, dolorem obcaecati. Cumque repellendus rerum veritatis reprehenderit maiores a, porro rem, molestias corrupti esse dolore nobis fuga odio."
                ghLink="https://"
              />
            </Col>

            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={ecommerce}
                isBlog={false}
                title="Ecommerce App"
                description="      Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores autem eos consequuntur, exercitationem esse repellat voluptates, quibusdam repudiandae corrupti voluptatem ducimus. Sit, dolorem obcaecati. Cumque repellendus rerum veritatis reprehenderit maiores a, porro rem, molestias corrupti esse dolore nobis fuga odio."
                ghLink="https://"

              />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}
export default Projectlist