import React from 'react';
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import imageone from '../../Assets/still life pumkin.jpg';
import imagetwo from '../../Assets/fruit.jpg';
import imagethree from '../../Assets/shapes.jpg';
import imagefour from '../../Assets/shapestwo.jpg';
import imagefive from '../../Assets/iron.jpg';
import imagesix from '../../Assets/shapesthree.jpg';
import imageseven from '../../Assets/Lopez-Donald-Infographic-072920.jpg';
import imageeight from '../../Assets/Adivsoct.jpg';
import imagenine from '../../Assets/Lopez-Donald-Painting-020419.jpg';
import imageten from '../../Assets/Ruby.jpg';
import imageeleven from '../../Assets/Sentinal.jpg';
import imagetwelve from '../../Assets/Orion.jpg';
import imagethirdteen from '../../Assets/Shadow2.jpg';
import imagefifteen from '../../Assets/RoS B1 pg4.jpg';
import imagesixteen from '../../Assets/RoS B1 pg3.jpg';
import imageseventeen from '../../Assets/Lopez-Donald-Logo-SimpleForm-072020.jpg';
import imageeighteen from '../../Assets/Kainclean.jpg';
import imagenineteen from '../../Assets/Fatality.jpg';
import imagetwenty from '../../Assets/Its a Me.jpg';
import imagetwentyone from '../../Assets/Sinjin1.jpg';
import imagetwentytwo from '../../Assets/Pane.jpg';
import imagetwentythree from '../../Assets/Reaper2.jpg';
import imagetwentyfour from '../../Assets/Orion2clean.jpg';
import imagetwentyfive from '../../Assets/octopencil.jpg';
import imagetwentysix from '../../Assets/Spike1 copy.jpg';
import imagetwentyseven from '../../Assets/Flame.jpg';
import imagetwentyeight from '../../Assets/Sinjin.jpg';
import imagetwentynine from '../../Assets/Alpha12clean.jpg';
import imagethirty from '../../Assets/Angel.jpg';
import imagethirtyone from '../../Assets/Cyborg2clean.jpg';
import imagethirtytwo from '../../Assets/Demon1clean.jpg';
import imagethirtythree from '../../Assets/Demon3.jpg';
import imagethirtyfour from '../../Assets/Lopez-Donald-Painting.jpg';

function Drawingpage() {
  return (
    <div className="aboutpagebackground">
      <div className="">
        <h2 className="text-center">
          <b>
            Drawings <span> and Paintings </span>
          </b>
        </h2>
      </div>
      {/*first column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imageone} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagethree} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*second column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefour} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwo} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*three column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col md={12}>
            <div className="aboutdetails">
              <img src={imageseven} width="100%" height="auto" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      {/*four column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imageeight} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagenine} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*five column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefive} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagesix} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*six column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imageten} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwelve} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*seven column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagethirdteen} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imageeleven} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
      {/*eight column*/}
      <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagefifteen} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagesixteen} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
           {/*nine column*/}
           <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imageseventeen} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imageeighteen} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
           {/*ten column*/}
           <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagenineteen} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwenty} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
           {/*eleven column*/}
           <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagetwentyone} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwentytwo} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
           {/*twevle column*/}
           <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagetwentythree} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwentyfour} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
            {/*thirdteen column*/}
            <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagetwentyfive} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwentysix} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
            {/*fourteen column*/}
            <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagetwentyseven} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagetwentyeight} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
            {/*fifteen column*/}
            <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagetwentynine} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagethirty} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
             {/*sixteen column*/}
             <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagethirtyone} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagethirtytwo} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
             {/*fifteen column*/}
             <Container>
        <Row className="textbackgroundtwo">
          <Col size="md">
            <img src={imagethirtythree} width="100%" height="auto" alt="" />
          </Col>
          <Col size="md">
            <img src={imagethirtyfour} width="100%" height="auto" alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Drawingpage;
