import React from 'react';
import '../../pages/style.css';
import { Container } from 'react-bootstrap';

function Contactpage() {
  return (
    <div className="contactbackground">
      <Container>
        <h2 className="contacthead">Get In Touch</h2>
        <p className="contactpara">
          {' '}
          I’m currently searching for opportunities for a full stack developer
          role. <br /> If there is any vacancy my inbox is always open. Whether{' '}
          <br /> you have any further questions or just want to say hi, <br />{' '}
          I’ll try my best to get back to you!
        </p>
        <form
          action="https://formsubmit.co/donaldlopez@donaldlopezart.com"
          method="POST"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="md-form mb-0">
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control"
                  required
                />
                <label for="name" class="">
                  Your name
                </label>
              </div>
            </div>

            <div class="col-md-6">
              <div class="md-form mb-0">
                <input
                  type="text"
                  id="email"
                  name="email"
                  class="form-control"
                  required
                />
                <label for="email" class="">
                  Your email
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  class="form-control"
                  required
                />
                <label for="subject" class="">
                  Subject
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-">
              <div class="md-form">
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  class="form-control md-textarea"
                  cols="30"
                  rows="3"
                  required
                ></textarea>
                <label for="message">Your message</label>
              </div>
            </div>
          </div>
          <div class="text-center text-md-left">
            <a href="contact.html">
              <button onClick="submit" class="btn btn-primary" type="submit">
                Send Message
              </button>
            </a>
          </div><br></br>
          <p className="text-center">or</p>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                window.open('https://wa.me/+9513850178');
              }}
            >
              Chat
            </button>
          </div>

          <span></span>
          <hr className="line" />
        </form>
        <p className="copyright">
          © Copyright 2023.
          <br />
          Designed & Built by <span>Donald</span>
        </p>
      </Container>
    </div>
  );
}

export default Contactpage;
