import React from 'react';
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import Text from '../Homepage/Text';
import {
  AiFillGithub,
  AiOutlineFacebook,
  AiFillInstagram,
} from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';

function Home() {
  return (
    <div className="homepagebackground">
      <Container>
        <Row>
          <Col md={7}>
            <h2 className="headtext">
              Hello <span className="wave">👋 </span>
            </h2>
            <h2 className="nametext">I'm Donald Lopez</h2>
            <span></span>
            <Text />
            <button
              onClick={() => {
                window.open('https://github.com/donaldlopez82?tab=repositories');
              }}
              className="socailmediabtn"
            >
              <AiFillGithub className="icon" />
            </button>
            <button
              onClick={() => {
                window.open('https://www.linkedin.com/in/donald-lopez-975794199/');
              }}
              className="socailmediabtn"
            >
              <FaLinkedinIn className="icon" />
            </button>
            <button
              onClick={() => {
                window.open('https://facebook.com/Donaldlopezart');
              }}
              className="socailmediabtn"
            >
              <AiOutlineFacebook className="icon" />
            </button>
            <button
              onClick={() => {
                window.open('https://instagram.com/doanldlopezart');
              }}  
              className="socailmediabtn"
            >
              <AiFillInstagram className="icon" />
            </button>
          </Col>

          <Col md={5}>
            <div className="imagedeveloper"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
